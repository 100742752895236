<template>
  <div class="shipments-continer">
    <div class="shopments-top">
      <span>发件人信息</span>
      <div class="top-message">
        <el-form ref="form" :rules="rules" label-width="110px">
          <el-form-item prop="xinxi" label="发件人信息 :">
            <el-select v-model="pp" @change="sj" placeholder="请选择">
              <el-option
                v-for="item in fname"
                :key="item.personalAddressId"
                :label="item.name"
                :value="item.personalAddressId"
              >
                <span style="float: left">{{ item.name }}</span>
                <el-tag
                  v-if="true"
                  size="mini"
                  effect="dark"
                  type="danger"
                  style="float: right; margin-top: 8px; margin-left: 3px"
                  @click.stop="handleDeleteTag(item, $event)"
                >
                  删除
                </el-tag>
              </el-option>

              <div class="addman" @click="open">+新增发件人</div>
              <div slot="empty" style="text-align: left">
                <div class="addman" style="height: 30px" @click="open">
                  +新增发件人
                </div>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="备注 :">
            <el-input
              type="textarea"
              placeholder="请输入备注"
              maxlength="200"
              show-word-limit
              v-model="desc"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-dialog title="新增" :visible.sync="dialogFormVisible">
        <div style="width: 80%; margin: auto">
          <el-form
            :model="form"
            :rules="rules2"
            ref="form"
            label-width="100px"
            label-position="right"
          >
            <el-form-item label="发件人姓名" prop="name">
              <el-input
                show-word-limit
                maxlength="16"
                style="width: 300px"
                size="mini"
                placeholder="请输入姓名"
                v-model="form.name"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input
                show-word-limit
                maxlength="11"
                style="width: 300px"
                size="mini"
                placeholder="请输入手机号码"
                v-model="form.phone"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="发件省市区" prop="area">
              <el-cascader
                v-model="form.area"
                :options="options2"
                @change="handleChange"
                clearable
                filterable
                :props="{
                  label: 'name',
                  value: 'code',
                  children: 'childs',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="date1">
              <el-input
                show-word-limit
                maxlength="200"
                style="width: 300px"
                size="mini"
                placeholder="请输入详细地址"
                v-model="form.date1"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="发件人单位" prop="date2">
              <el-input
                show-word-limit
                maxlength="16"
                style="width: 300px"
                size="mini"
                placeholder="请输入单位"
                v-model="form.date2"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="affshow">取 消</el-button>
          <el-button type="primary" @click="affirm('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="shopments-end">
      <span class="s-text">收件人信息</span>
      <div class="s-message">
        <div class="s-a">
          <div class="s-left">
            <el-button size="small" @click="choise()">选择收件人</el-button>
            <el-dialog title="选择收件人" :visible.sync="sjr">
              <el-form :model="form">
                <el-form-item label="收件人名称 :">
                  <el-select v-model="sjname" placeholder="请选择">
                    <el-option
                      v-for="item in sname"
                      :key="item.personalAddressId"
                      :label="item.name"
                      :value="item.personalAddressId"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <el-tag
                        v-if="true"
                        size="mini"
                        effect="dark"
                        type="danger"
                        style="float: right; margin-top: 8px; margin-left: 3px"
                        @click.stop="handleDelete2(item, $event)"
                      >
                        删除
                      </el-tag>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="sjr = false">取 消</el-button>
                <el-button type="primary" @click="saffirm()">确 定</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="s-right">
            <el-checkbox
              :disabled="diabshow"
              v-model="checked"
              @change="addressee"
              >保存到收件人</el-checkbox
            >
          </div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="收件人姓名" prop="name">
            <el-input
              v-model="ruleForm.name"
              maxlength="16"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              maxlength="11"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="收件省市区" prop="area">
            <el-cascader
              v-model="ruleForm.area"
              :options="options2"
              clearable
              filterable
              :props="{
                label: 'name',
                value: 'code',
                children: 'childs',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="site">
            <el-input
              v-model="ruleForm.site"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="收件人单位">
            <el-input
              v-model="ruleForm.unit"
              maxlength="16"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item label="包裹数量:" label-width="100px">
                <el-input v-model="ruleForm.num"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="包裹重量:" prop="kg" label-width="100px">
                <div style="display: flex">
                  <el-input v-model="ruleForm.kg"></el-input>kg
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="商品信息 :" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择">
              <el-option
                v-for="item in shoparr"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Province,
  GetList,
  AddOrEdit,
  shopList,
  AddOrderForPersonal,
  GetDetail,
  EditOrderForPersonal,
  bjdetail,
  Delete,
} from "../../api/prints";
import { GetSiteCodeByName } from "../../api/Dic";

export default {
  data() {
    var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("号码不能为空!!"));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error("格式有误"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      diabshow: false,
      statusnum: 0, // 0是新增1是编辑
      zjid: "", //发件人主键id
      fname: [], //发件人列表
      sname: [], //收件人列表
      sjname: "", //收件人名称
      dialogFormVisible: false, //发件人弹窗
      sjr: false, //收件人弹窗
      areaprinv: [], //发件人省市区信息
      areaprinv2: [], //收件人省市区信息
      options2: [], //省市区
      value: [],
      pp: "", //收货人
      desc: "", //备注
      checked: false, //保存到收件人
      deilte: {}, //发件人信息
      shoparr: [], //商品信息列表
      form: {
        name: "",
        phone: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        area: [],
        xinxi: "",
      },
      ruleForm: {
        name: "",
        phone: "",
        resource: "",
        desc: "",
        site: "",
        unit: "",
        user: "",
        num: 1,
        kg: "",
        region: "",
        area: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入收件人姓名", trigger: "blur" },
        ],
        xinxi: [
          { required: true, message: "请输入发件人信息", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: "",
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        area: [{ required: true, message: "请输入省市区", trigger: "blur" }],
        region: [{ required: true, message: "请选择商品", trigger: "change" }],
        site: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        kg: [{ required: true, message: "请输入重量", trigger: "blur" }],
      },
      rules2: {
        name: [
          { required: true, message: "请输入发件人姓名", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: "",
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        area: [{ required: true, message: "请选择省市区", trigger: "change" }],

        date1: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        date2: [
          { required: true, message: "请输入发件人单位", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //删除收件人
    handleDelete2(tag) {
      let data = {
        id: tag.personalAddressId,
      };
      this.$confirm("确认删除此标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        Delete(data).then((res) => {
          if (res.code === 10000) {
            this.sGetList();
            this.$message.success("删除成功");
          } else {
            this.$message(res.msg);
          }
        });
      });
    },
    //删除发件人
    handleDeleteTag(tag) {
      let data = {
        id: tag.personalAddressId,
      };
      this.$confirm("确认删除此标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        Delete(data).then((res) => {
          if (res.code === 10000) {
            this.fGetList();
            this.$message.success("删除成功");
          } else {
            this.$message(res.msg);
          }
        });
      });
    },
    open() {
      this.dialogFormVisible = true;
    },
    addressee(val) {
      console.log(this.ruleForm.area);
      let data = {
        personalAddressId: 0,
        personalAddressType: 2,
        address: this.ruleForm.site,
        provinceCode: this.ruleForm.area[0],
        cityCode: this.ruleForm.area[1],
        countyCode: this.ruleForm.area[2],
        company: this.ruleForm.unit,
        mobile: this.ruleForm.phone,
        name: this.ruleForm.name,
      };
      if (val == true) {
        AddOrEdit(data).then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.$message("保存成功");
            this.sGetList();
          } else if (res.code == -1) {
            this.$message(res.msg);
            this.checked = false;
          }
        });
      }
    },
    sj(val) {
      console.log(val);
      let data = {
        id: val,
      };
      GetDetail(data)
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.deilte = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择联系人
    choise() {
      this.sjr = true;
    },
    //收件人名称弹窗确定
    saffirm() {
      this.sjr = false;
      console.log(this.sjname);
      let data = {
        id: this.sjname,
      };
      GetDetail(data).then((res) => {
        console.log(res);

        (this.ruleForm.name = res.data.name),
          (this.ruleForm.phone = res.data.mobile),
          (this.ruleForm.area = [
            res.data.provinceCode,
            res.data.cityCode,
            res.data.countyCode,
          ]),
          (this.ruleForm.site = res.data.address),
          (this.ruleForm.unit = res.data.company);
        this.sGetList();
        this.diabshow = true;
      });
      // this.ruleForm.name = this.sjname;
      // this.sjr = false;
    },
    //省市区
    handleChange(value) {
      console.log(value);
      this.areaprinv = value;
    },
    //点击确认新增
    affirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            personalAddressId: 0,
            personalAddressType: 1,
            address: this.form.date1,
            provinceCode: this.form.area[0],
            cityCode: this.form.area[1],
            countyCode: this.form.area[2],
            company: this.form.date2,
            mobile: this.form.phone,
            name: this.form.name,
          };
          AddOrEdit(data).then((res) => {
            console.log(res);
            if (res.code === 10000) {
              this.$message("新增成功");
              this.dialogFormVisible = false;
              this.form = {};
              this.fGetList();
            } else if (res.code == -1) {
              // alert(res.msg)
              this.$message(res.msg);
            }
          });
        }
      });
    },
    affshow() {
      this.dialogFormVisible = false;
      this.form = {};
    },

    //保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            orderFromWhere: 100004,
            logisticsCompanyCode: 0,
            goodsList: [
              {
                name: this.ruleForm.region,
                weight: this.ruleForm.kg,
                quantity: Number(this.ruleForm.num),
              },
            ],
            //发件人信息
            userSender: {
              name: this.deilte.name,
              mobile: this.deilte.mobile,
              province: this.deilte.province,
              city: this.deilte.city,
              county: this.deilte.county,
              addressDetail: this.deilte.address,
              companyName: this.deilte.company,
            },
            //  收件人信息
            userReceiver: {
              name: this.ruleForm.name,
              mobile: this.ruleForm.phone,
              province: this.getSiteName(this.ruleForm.area[0]),
              city: this.getSiteName(this.ruleForm.area[1]),
              county: this.getSiteName(this.ruleForm.area[2]),
              addressDetail: this.ruleForm.site,
              companyName: this.ruleForm.unit,
            },
          };
          if (this.statusnum === 0) {
            AddOrderForPersonal(data)
              .then((res) => {
                console.log(res);
                if (res.code === 10000) {
                  this.$message("新增成功");
                  this.desc = "";
                  this.pp = "";
                  this.ruleForm = "";
                  this.$router.push("/logorders");
                } else {
                  this.$message(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (this.statusnum === 1) {
            console.log("开始编辑");
            let data = {
              id: this.$route.params.way,
              orderFromWhere: 100004,
              logisticsCompanyCode: 0,
              goodsList: [
                {
                  name: this.ruleForm.shop,
                  weight: this.ruleForm.kg,
                  quantity: this.ruleForm.num,
                },
              ],
              //发件人信息
              userSender: {
                name: this.deilte.name,
                mobile: this.deilte.mobile,
                province: this.deilte.province,
                city: this.deilte.city,
                county: this.deilte.county,
                addressDetail: this.deilte.address,
                companyName: this.deilte.company,
              },
              //  收件人信息
              userReceiver: {
                name: this.ruleForm.name,
                mobile: this.ruleForm.phone,
                province: this.getSiteName(this.ruleForm.area[0]),
                city: this.getSiteName(this.ruleForm.area[1]),
                county: this.getSiteName(this.ruleForm.area[2]),
                addressDetail: this.ruleForm.site,
                companyName: this.ruleForm.unit,
              },
            };
            EditOrderForPersonal(data)
              .then((res) => {
                console.log(res);
                if (res.code === 10000) {
                  this.$message("编辑成功");
                  this.desc = "";
                  this.pp = "";
                  this.ruleForm = "";
                  this.$router.push({
                    path: "/logorders",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
        if (this.statusnum === 0) {
          AddOrderForPersonal(data)
            .then((res) => {
              console.log(res);
              if (res.code === 10000) {
                this.$message("新增成功");
                this.desc = "";
                this.pp = "";
                this.ruleForm = "";
                this.$router.push("/logorders");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    //获取收件人
    sGetList() {
      let data = {
        personalAddressType: 2,
      };
      GetList(data).then((res) => {
        console.log(res);
        if (res.code === 10000) {
          this.sname = res.data;
        }
      });
    },
    //获取发件人
    fGetList() {
      let data = {
        personalAddressType: 1,
      };
      GetList(data).then((res) => {
        console.log(res);
        if (res.code === 10000) {
          this.fname = res.data;
        }
      });
    },
    //获取商品列表
    sshopList() {
      shopList()
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.shoparr = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //重置
    resetForm() {
      this.desc = "";
      this.pp = "";
      this.ruleForm = {};
    }, //得到区域名字
    getSiteName(code) {
      for (var i = 0; i < this.options2.length; i++) {
        if (this.options2[i].code == code) {
          return this.options2[i].name;
        }
      }
      for (var i = 0; i < this.options2.length; i++) {
        if (this.options2[i].childs) {
          for (var i2 = 0; i2 < this.options2[i].childs.length; i2++) {
            if (this.options2[i].childs[i2].code == code) {
              return this.options2[i].childs[i2].name;
            }
          }
        }
      }
      for (var i = 0; i < this.options2.length; i++) {
        if (this.options2[i].childs) {
          for (var i2 = 0; i2 < this.options2[i].childs.length; i2++) {
            if (this.options2[i].childs[i2].childs) {
              for (
                var i3 = 0;
                i3 < this.options2[i].childs[i2].childs.length;
                i3++
              ) {
                if (this.options2[i].childs[i2].childs[i3].code == code) {
                  return this.options2[i].childs[i2].childs[i3].name;
                }
              }
            }
          }
        }
      }
      return "";
    }, //得到区域编号type:1,省，2市，3区    
  },
  created() {
    //获得省市区
    Province()
      .then((res) => {
        // console.log(res)
        if (res.code === 10000) {
          this.options2 = res.data;
          console.log(this.options);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.sshopList();
    this.fGetList();
    this.sGetList();
    this.statusnum = 0;
    //  console.log(this.$route.params.way)
    if (this.$route.params.way) {
      this.statusnum = 1;
      let data = {
        id: this.$route.params.way,
      };
      bjdetail(data)
        .then((res) => {
          console.log(res);
          console.log(res.data.goodsList[0].quantity);
          if (res.code === 10000) {
            (this.pp = res.data.userSender.name),
              (this.desc = res.data.userSender.remark),
              (this.ruleForm.name = res.data.userReceiver.name),
              (this.ruleForm.phone = res.data.userReceiver.mobile),
              (this.ruleForm.site = res.data.userReceiver.addressDetail),
              (this.ruleForm.unit = res.data.userReceiver.companyName),
              (this.ruleForm.num = res.data.goodsList[0].quantity),
              (this.ruleForm.kg = 1),
              (this.ruleForm.shop = res.data.goodsList[0].name);

            this.ruleForm.area[0] = res.data.userReceiver.provinceCode;
            this.ruleForm.area[1] = res.data.userReceiver.cityCode;
            this.ruleForm.area[2] = res.data.userReceiver.countyCode;
            this.ruleForm.region = res.data.goods.name;
            let data = {
              id: res.data.userSender.personalAddressId,
            };
            //绑发件人
            GetDetail(data)
              .then((res) => {
                console.log(res);
                if (res.code === 10000) {
                  this.deilte = res.data;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(this.statusnum);
    }
    console.log(this.statusnum);
    // console.log(localStorage.getItem('token'))
  },
};
</script>

<style scoped>
.shipments-continer {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  background: #ddd;
}
.shopments-top {
  width: 100%;
  height: 30%;
  background: white;
  border-radius: 6px;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  font-weight: 700;
}
.top-message {
  width: 320px;
  height: 85%;
  /* background: pink; */
  margin-left: 142px;
}
.addman {
  text-align: center;
  font-size: 13px;
  color: #6699ff;
  cursor: pointer;
  line-height: 30px;
}
.shopments-end {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 6px;
  margin-top: 15px;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  font-weight: 700;
}
.s-text {
  float: left;
}
.s-message {
  width: 350px;
  height: 100%;
  /* background: pink; */
  margin-left: 30px;
  float: left;
}
.s-left {
  float: left;
}
.s-right {
  float: right;
}
.s-a {
  height: 44px;
}
</style>
